import Vue from 'vue'
import 'core-js/es/reflect' // IE11 shim
import '@webcomponents/webcomponentsjs/webcomponents-bundle' // IE11 web component polyfill, can be removed once support ends
import wrap from '@vue/web-component-wrapper'

// import fonts from Google Fonts manually to get around Shadow DOM font-face limitations
// see: https://stackoverflow.com/questions/55382081/using-google-fonts-with-shadow-dom
var fontLink = document.createElement('link')
fontLink.type = 'text/css'
fontLink.rel = 'stylesheet'
fontLink.href = 'https://fonts.googleapis.com/css2?family=Karla:wght@700&family=Lato:ital,wght@0,400;0,700;1,400&display=swap'
document.head.appendChild(fontLink)

// Import each built component manually here:

// Breadcrumb
const Breadcrumb = wrap(Vue, () => import('@/elements/WvcBreadcrumb.vue'))
window.customElements.define('wvc-breadcrumb', Breadcrumb)

// Button
const Button = wrap(Vue, () => import('@/elements/WvcButton.vue'))
window.customElements.define('wvc-button', Button)

// Card
const Card = wrap(Vue, () => import('@/elements/WvcCard.vue'))
window.customElements.define('wvc-card', Card)

// Breadcrumb
const Crumb = wrap(Vue, () => import('@/elements/WvcCrumb.vue'))
window.customElements.define('wvc-crumb', Crumb)

// Divider
const Divider = wrap(Vue, () => import('@/elements/WvcDivider.vue'))
window.customElements.define('wvc-divider', Divider)

// Download Button
const DownloadButton = wrap(Vue, () => import('@/elements/WvcDownloadButton.vue'))
window.customElements.define('wvc-download-button', DownloadButton)

// Link
const Link = wrap(Vue, () => import('@/elements/WvcLink.vue'))
window.customElements.define('wvc-link', Link)

// LinkedList
const List = wrap(Vue, () => import('@/elements/WvcList.js'))
window.customElements.define('wvc-list', List)

// Row
const Row = wrap(Vue, () => import('@/elements/WvcRow.vue'))
window.customElements.define('wvc-row', Row)

// ProductTag
const ProductTag = wrap(Vue, () => import('@/elements/WvcProductTag.vue'))
window.customElements.define('wvc-product-tag', ProductTag)

// Tab
const Tab = wrap(Vue, () => import('@/elements/WvcTab.vue'))
window.customElements.define('wvc-tab', Tab)

// Tabs
const Tabs = wrap(Vue, () => import('@/elements/WvcTabs.vue'))
window.customElements.define('wvc-tabs', Tabs)

// Legal Footer
const LegalFooter = wrap(Vue, () => import('@/templates/WvcLegalFooter.vue'))
window.customElements.define('wvc-legal-footer', LegalFooter)

// Feedback
const Feedback = wrap(Vue, () => import('@/elements/WvcFeedback.vue'))
window.customElements.define('wvc-feedback', Feedback)

// Submit Button
const SubmitButton = wrap(Vue, () => import('@/patterns/WvcSubmitButton.vue'))
window.customElements.define('wvc-submit-button', SubmitButton)

// Dropdown navigation
const DropdownNav = wrap(Vue, () => import('@/elements/WvcDropdownNav.vue'))
window.customElements.define('wvc-dropdown-nav', DropdownNav)

const CategoryCard = wrap(Vue, () => import('@/elements/WvcCategoryCard.vue'))
window.customElements.define('wvc-category-card', CategoryCard)

// Dropdown
const Dropdown = wrap(Vue, () => import('@/elements/WvcDropdown.vue'))
window.customElements.define('wvc-dropdown', Dropdown)

// Pagination
const Pagination = wrap(Vue, () => import('@/elements/WvcPagination.vue'))
window.customElements.define('wvc-pagination', Pagination)

// Financial Chart
const FinancialChart = wrap(Vue, () => import('@/patterns/WvcFinancialChart.vue'))
window.customElements.define('wvc-financial-chart', FinancialChart)

// Social Share Button
const SocialShareButton = wrap(Vue, () => import('@/elements/WvcSocialShareButton.vue'))
window.customElements.define('wvc-social-share-button', SocialShareButton)

// Secondary Nav Links (only for use in secondary nav)
const SecondaryNavLink = wrap(Vue, () => import('@/elements/WvcSecondaryNavLink.vue'))
window.customElements.define('wvc-secondary-nav-link', SecondaryNavLink)
